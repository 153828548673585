<template>
  <v-container
    id="member-resume"
    fluid
    tag="section"
  >
    <v-dialog
      v-model="loader"
      persistent
      width="500"
    >
      <div class="text-center transparent py-5">
        <v-progress-circular
          :size="50"
          :width="7"
          color="indigo"
          indeterminate
        />
      </div>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>

    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
      >
        <v-img
          :width="300"
          src="@/assets/logo.png"
        />
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
      >
        <div
          v-if="esRenovacion"
          :class="$vuetify.breakpoint.lgAndUp?'text-h3':'text-h4'"
          class="text-center font-weight-medium my-2"
        >
          Revise las diferentes opciones de Renovación, y seleccione la que se ajusta a su necesidad
          <br>
          <span class="text-h5">
            (si desea verificar la información que brindó en el formulario, puede bajar al fondo de
            esta ventana, y usar la opción de Modificar si así lo requiere)
          </span>
        </div>
        <div
          v-else
          :class="$vuetify.breakpoint.lgAndUp?'text-h3':'text-h4'"
          class="text-center font-weight-medium my-2"
        >
          Opción de pago para el grupo seleccionado
          <br>
          <span class="text-h5">
            (si desea verificar la información que brindó en el formulario, puede bajar al fondo de
            esta ventana, y usar la opción de Modificar si así lo requiere)
          </span>
        </div>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
      >
        <v-row
          class="justify-center"
        >
          <v-col
            v-for="(elemento) in grupos"
            :key="elemento.id"
            class="text-center"
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl="3"
          >
            <base-material-card
              color="primary"
            >
              <template v-slot:heading>
                <div class="text-h3 font-weight-light">
                  {{ elemento.grupo }}
                </div>
              </template>
              <v-card-text
                v-if="revealForm"
                class="text-center"
              >
                <div class="container">
                  <v-row align="center">
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <v-card-text>
                        <v-row
                          justify="center"
                          align="center"
                        >
                          <v-col
                            cols="6"
                            class="text-center font-weight-regular"
                          >
                            Membresía {{ elemento.grupo }}
                          </v-col>
                          <v-col
                            cols="6"
                            class="text-center font-weight-regular"
                          >
                            {{ elemento.valor | toBaseAmount }}
                          </v-col>
                        </v-row>
                        <v-row
                          justify="center"
                          align="center"
                        >
                          <v-col
                            cols="6"
                            class="text-center font-weight-regular"
                          >
                            IVA (16%)
                          </v-col>
                          <v-col
                            cols="6"
                            class="text-center font-weight-regular"
                          >
                            {{ elemento.valor | toTaxValue }}
                          </v-col>
                        </v-row>
                        <v-row
                          justify="center"
                          align="center"
                        >
                          <v-col
                            cols="6"
                            class="text-center font-weight-regular font-weight-black"
                          >
                            TOTAL
                          </v-col>
                          <v-col
                            cols="6"
                            class="text-center font-weight-regular font-weight-black"
                          >
                            {{ elemento.valor | toTotal }}
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <div class="text-center">
                        <!-- Medios de pago -->
                        <div class="my-2">
                          <v-btn
                            elevation="2"
                            outlined
                            type="button"
                            class="mt-4"
                            block
                            @click="goToPaymentMethod(elemento, 'card')"
                          >
                            Tarjeta Débito/Crédito
                          </v-btn>
                        </div>
                        <!-- <div class="my-2">
                        <v-btn
                          elevation="2"
                          outlined
                          type="button"
                          class="mt-4"
                          block
                          @click="goToPaymentMethod(elemento, 'transfer')"
                        >
                          Transferencia bancaria
                        </v-btn>
                      </div> -->
                        <!-- <div class="my-2">
                        <v-btn
                          elevation="2"
                          outlined
                          type="button"
                          class="mt-4"
                          block
                          @click="goToPaymentMethod(elemento, 'paypal')"
                        >
                          PayPal
                        </v-btn>
                      </div> -->
                        <div class="my-2">
                          <v-btn
                            elevation="2"
                            outlined
                            type="button"
                            class="mt-4"
                            block
                            @click="goToPaymentMethod(elemento, 'installments')"
                          >
                            Pago en 3 cuotas sin intereses
                          </v-btn>
                        </div>
                        <!-- <div class="my-2">
                        <v-btn
                          elevation="2"
                          outlined
                          type="button"
                          class="mt-4"
                          block
                          @click="goToPaymentMethod(elemento, 'sepa')"
                        >
                          Domiciliación anual SEPA
                        </v-btn>
                      </div> -->
                        <div class="my-2">
                          <v-dialog
                            transition="dialog-bottom-transition"
                            max-width="600"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                elevation="2"
                                outlined
                                name="hola"
                                class="mt-4"
                                block
                                v-bind="attrs"
                                v-on="on"
                              >
                                Transferencia bancaria
                              </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-toolbar
                                  color="primary"
                                  dark
                                  class="text-h3 text-uppercase"
                                >
                                  Importe {{ elemento.grupo }}
                                </v-toolbar>
                                <v-card-text>
                                  <v-row
                                    justify="center"
                                    align="center"
                                  >
                                    <v-col
                                      cols="8"
                                      class="text-center font-weight-regular"
                                    >
                                      Membresía {{ elemento.grupo }}
                                    </v-col>
                                    <v-col
                                      cols="4"
                                      class="text-center font-weight-regular"
                                    >
                                      {{ elemento.valor | toBaseAmount }}
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    justify="center"
                                    align="center"
                                  >
                                    <v-col
                                      cols="8"
                                      class="text-center font-weight-regular"
                                    >
                                      IVA (16%)
                                    </v-col>
                                    <v-col
                                      cols="4"
                                      class="text-center font-weight-regular"
                                    >
                                      {{ elemento.valor | toTaxValue }}
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    justify="center"
                                    align="center"
                                  >
                                    <v-col
                                      cols="8"
                                      class="text-center font-weight-regular font-weight-black"
                                    >
                                      TOTAL
                                    </v-col>
                                    <v-col
                                      cols="4"
                                      class="text-center font-weight-regular font-weight-black"
                                    >
                                      {{ elemento.valor | toTotal }}
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    justify="center"
                                    align="center"
                                  >
                                    <v-col
                                      cols="12"
                                      class="font-weight-regular mt-5"
                                    >
                                      <p class="text-center">
                                        <b>DATOS PARA TRANSFERENCIA BANCARIA</b>
                                      </p>
                                      <p class="text-center">
                                        Banco: Banorte
                                      </p>
                                      <p class="text-center">
                                        N° de cuenta: 1146196576
                                      </p>
                                      <p class="text-center">
                                        CLABE interbancaria: 072 420 0114 6196 576 3
                                      </p>
                                      <p class="text-center">
                                        Beneficiario: Nacom 21 S. de R.L. de C.V.
                                      </p>
                                      <p class="text-center">
                                        Concepto: Membresía BNI (nombre y apellidos miembro)
                                      </p>
                                      <br>
                                      <p class="text-justify">
                                        <b>IMPORTANTE:</b> Si paga por transferencia, y no sube el justificante, no se dará por cerrado el expediente. Al cargar el justificante, es como nos notifica de su pago. Un administrador procederá a revisar el fichero cargado por usted, y verificará su pago si todo está correcto. Recibirá un e-mail cuando su pago haya sido aprobado.
                                      </p>
                                      <br>
                                      <v-file-input
                                        v-model="suscripcion.comprobante"
                                        accept=".png,.jpeg,.pdf"
                                        label="Cargar comprobante"
                                      />
                                      <v-btn
                                        block
                                        color="primary"
                                        class="ma-2 white--text"
                                        @click="enviarDocumento(elemento.id)"
                                      >
                                        Enviar comprobante
                                        <v-icon
                                          right
                                          dark
                                        >
                                          mdi-cloud-upload
                                        </v-icon>
                                      </v-btn>
                                    <!-- <br>
                                    <p class="mb-0">
                                      <b>Enviar justificante de pago al correo:</b>
                                    </p>
                                    <p class="mb-0">
                                      andres.garcia@bnimadridcentrosuroeste.com
                                    </p> -->
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                    text
                                    @click="dialog.value = false"
                                  >
                                    Cerrar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>
                        </div>
                        <!-- // Medios de pago -->
                        <!-- Constancia fiscal -->
                        <div
                          v-if="suscripcion.miembro.fechaCargaConstFiscal === null"
                          class="pt-5 mt-5"
                        >
                          Si desea solicitar factura, es necesario que adjunte
                          primero su Constancia fiscal
                        </div>
                        <div
                          v-else
                          class="pt-5 mt-5"
                        >
                          Ya ha cargado su Constancia fiscal. Si desea actualizar
                          el archivo, puede utilizar esta opción:
                        </div>
                        <div class="my-2">
                          <v-dialog
                            transition="dialog-bottom-transition"
                            max-width="600"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-if="suscripcion.miembro.fechaCargaConstFiscal === null"
                                elevation="2"
                                class="mt-4"
                                color="primary"
                                block
                                v-bind="attrs"
                                v-on="on"
                              >
                                Adjuntar Constancia fiscal
                              </v-btn>
                              <v-btn
                                v-else
                                elevation="2"
                                class="mt-4"
                                color="primary"
                                block
                                v-bind="attrs"
                                v-on="on"
                              >
                                Actualizar Constancia fiscal
                              </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-toolbar
                                  color="primary"
                                  dark
                                  class="text-h3 text-uppercase"
                                >
                                  Constancia fiscal
                                </v-toolbar>
                                <v-card-text>
                                  <v-row
                                    justify="center"
                                    align="center"
                                  >
                                    <v-col
                                      cols="12"
                                      class="font-weight-regular"
                                    >
                                      <v-file-input
                                        v-model="suscripcion.miembro.constanciaFiscal"
                                        class="my-5"
                                        accept=".png,.jpeg,.pdf"
                                        label="Cargar constancia"
                                      />
                                      <v-btn
                                        block
                                        color="primary"
                                        class="ma-2 white--text"
                                        @click="cargarConstancia(suscripcion.miembro)"
                                      >
                                        Enviar constancia
                                        <v-icon
                                          right
                                          dark
                                        >
                                          mdi-cloud-upload
                                        </v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                    text
                                    @click="dialog.value = false"
                                  >
                                    Cerrar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>
                        </div>
                      <!-- // Constancia fiscal -->
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>

              <v-expand-transition>
                <v-card
                  v-if="reveal"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 0%;"
                >
                  <v-card-title>
                    <div class="green darken-2 text-center rounded mx-auto mb-4 pa-4 d-block">
                      <v-icon
                        large
                        color="white"
                      >
                        mdi-check-circle
                      </v-icon>
                      <span class="text-h3 font-weight-bold white--text text-needs-wrapping"> Pago realizado con éxito</span>
                    </div>
                  </v-card-title>
                  <v-card-text class="pb-0">
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <p>Muchas gracias, hemos recibido tu pago correctamente, recibirás en tu correo una notificación con los datos de la transacción realizada.</p>
                      </v-col>
                    </v-row>
                    <v-subheader>Detalles del pago realizado</v-subheader>
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <span class="font-weight-regular">Número de transacción: </span>
                        <span class="font-weight-light">{{ transaccion.transId }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <span class="font-weight-regular">Referencia pedido: </span>
                        <span class="font-weight-light">{{ transaccion.orderId }}</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      to="/member/register"
                    >
                      SALIR
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </base-material-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
      >
        <base-material-card
          color="primary"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Resumen de la información
            </div>

            <div class="text-subtitle-1 font-weight-light">
              Por favor verifica tus datos.
            </div>
          </template>

          <v-subheader>Información del grupo</v-subheader>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Grupo seleccionado: </span>
              <span class="font-weight-light">{{ suscripcion.grupo.grupo }}</span>
            </v-col>
          </v-row>

          <v-subheader>Información personal</v-subheader>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Nombre: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.nombre }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Apellidos: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.apellido }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Email: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.mail }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Teléfono: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.telefono }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Giro o Especialidad a representar en BNI: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.especialidad }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Invitado por: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.invito }}</span>
            </v-col>
          </v-row>

          <v-subheader>Información de facturación</v-subheader>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Razón social/Nombre fiscal: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.razon }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">RFC: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.cif }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Dirección fiscal: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.domicilio }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Ciudad: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.poblacion }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Estado: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.provincia }}</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <span class="font-weight-medium">Código postal: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.postal }}</span>
            </v-col>
          </v-row>

          <v-subheader>Información de networking</v-subheader>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <span class="font-weight-medium">¿Ha sido miembro de BNI anteriormente?: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.miembroBNI }}</span>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <span class="font-weight-medium">En caso afirmativo, ¿cuándo y en qué grupo?: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.grupoBNIAnt }}</span>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <span class="font-weight-medium">¿Es miembro actualmente de alguna otra organización de networking cuyo objetivo sea también el intercambio de referencias?: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.miembroOrgQ }}</span>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <span class="font-weight-medium">En caso afirmativo, ¿a cuál?: </span>
              <span class="font-weight-light">{{ suscripcion.miembro.miembroOrgA }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-btn
                color="primary"
                :disabled="bloquearModificar"
                @click="deleteSuscripcion()"
              >
                MODIFICAR
              </v-btn>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>

    <!-- Formulario que se envía a la ventana externa de pago -->
    <form
      ref="paymentForm"
      :action="formularioLyra.form.action"
      :method="formularioLyra.form.method"
      accept-charset="UTF-8"
      :enctype="formularioLyra.form.enctype"
    >
      <input
        v-for="(value, name) in formularioLyra.fields"
        :key="name"
        type="hidden"
        :name="name"
        :value="value"
      >
    </form>
    <!-- // Formulario que se envía a la ventana externa de pago -->
  </v-container>
</template>

<script>
  import SuscriptionApi from '@/services/api/Suscripciones'
  import MemberApi from '@/services/api/Miembros'

  export default {
    filters: {
      toBaseAmount (value) {
        if (undefined !== value) {
          value = value.replace(',', '')
          let base = parseFloat(value)
          base = (base * 100) / 116
          const formatter = new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
          })
          return formatter.format(base) + 'MXN'
        }
      },
      toTaxValue (value) {
        if (undefined !== value) {
          value = value.replace(',', '')
          let tax = parseFloat(value)
          tax = tax = (tax * 16) / 116
          const formatter = new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
          })
          return formatter.format(tax) + 'MXN'
        }
      },
      toTotal (value) {
        if (undefined !== value) {
          value = value.replace(',', '')
          const formatter = new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
          })
          return formatter.format(value) + 'MXN'
        }
      },
    },
    data: () => ({
      panel: [0],
      grupos: [],
      esRenovacion: false,
      suscripcion: {
        id: '',
        estado: '',
        fecha: '',
        grupo: [],
        miembro: {
          id: '',
          nombre: '',
          apellido: '',
          mail: '',
          telefono: '',
          especialidad: '',
          invito: '',
          razon: '',
          cif: '',
          domicilio: '',
          poblacion: '',
          provincia: '',
          postal: '',
          constanciaFiscal: null,
          fechaCargaConstFiscal: null,
        },
        comprobante: '',
      },
      formularioLyra: {
        fields: [],
        form: [],
        grupo: [],
      },
      reveal: false,
      revealForm: true,
      transaccion: {
        transId: '',
        orderId: '',
      },
      loader: false,
      snackbar: false,
      colorSnak: 'info',
      text: '',
      timeout: 3000,
      bloquearModificar: true,
      rutaPrevia: null,
    }),
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.rutaPrevia = from
      })
    },
    mounted () {
      window.onpageshow = function (event) {
        if (event.persisted) {
          window.location.reload()
        }
      }
      this.initialize()
    },
    methods: {
      fetchResume () {
        SuscriptionApi.getSuscripcionByToken(this.$route.params.token)
          .then(suscripcion => {
            this.suscripcion = suscripcion.suscripcion
            this.grupos = suscripcion.grupos

            if (this.suscripcion.es_renovacion === '1') {
              this.esRenovacion = true
            }

            if (parseInt(this.suscripcion.miembro.eliminado) === 1) {
              this.$router.push({ name: 'Member Register' })
            } else if (this.suscripcion.estado !== 'PAGADO') {
              this.bloquearModificar = false
            } else {
              this.reveal = true
              this.revealForm = false
              this.transaccion.transId = this.suscripcion.lyra_trans_id
              this.transaccion.orderId = this.suscripcion.token
            }
          })
          .catch(error => {
            this.revealForm = false
            console.log(error)
            this.text = 'Suscripción no encontrada. Verique si ha recibido un mail con un enlace más reciente'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },
      initialize () {
        this.fetchResume()
      },
      goToPaymentMethod (group, method) {
        this.loader = true

        const formData = {
          suscripcion: this.suscripcion,
          grupo: group,
          metodo: method,
        }
        SuscriptionApi.generatePaymentData(formData)
          .then(response => {
            // Verificar el vads_trans_id que se genera
            // console.log(suscripcion.lyraForms[0].fields.vads_trans_id)
            this.formularioLyra = response.paymentForm

            /**
             * Esperar un momento, para asegurar que el formulario se rellena
             * con toda la información antes del submit.
             *
             * Con esta acción se redirige a la ventana de pago de Lyra
             */
            setTimeout(() => {
              this.loader = false
              this.$refs.paymentForm.submit()
            }, 1500)
          })
          .catch(error => {
            this.loader = false
            this.revealForm = false
            console.log(error)
            this.text = 'Ha ocurrido un error. Intente nuevamente o póganse en contacto con nosotros.'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },
      enviarDocumento (grupoId) {
        const archivo = this.suscripcion.comprobante
        if (!archivo || archivo == null) {
          this.text = 'Debes seleccionar un documento'
          this.colorSnak = 'info'
          this.snackbar = true
        } else {
          const formData = new FormData()
          formData.append('_file', this.suscripcion.comprobante)
          formData.append('suscripcion_id', this.suscripcion.id)
          formData.append('grupo_id', grupoId)
          formData.append('oficial', true)

          SuscriptionApi.uploadFile(formData)
            .then(response => {
              this.text = 'Documento cargado correctamente. Recibirá un correo de confirmación'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'El documento no puede ser cargado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },
      cargarConstancia (miembro) {
        const file = this.suscripcion.miembro.constanciaFiscal
        if (!file || file == null) {
          this.text = 'Debes seleccionar un documento'
          this.colorSnak = 'info'
          this.snackbar = true
        } else {
          const formData = new FormData()
          formData.append('_file', file)
          formData.append('miembro_id', this.suscripcion.miembro.id)
          formData.append('oficial', true)

          MemberApi.uploadConstanciaFiscal(formData)
            .then(response => {
              this.text = 'Documento cargado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true

              this.initialize()
            })
            .catch(error => {
              console.log(error)
              this.text = 'El documento no puede ser cargado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },
      deleteSuscripcion () {
        SuscriptionApi.deleteSuscripcion(this.suscripcion.id)
          .then(response => {
            this.$router.push({ name: 'Member Token Register', params: { token: this.suscripcion.miembro.token } })
            /* if (this.rutaPrevia.name === 'Member Renewal') {
              this.$router.push({ name: 'Member Renewal', params: { grupo: this.suscripcion.grupo, mail: this.suscripcion.miembro.mail } })
            } else {
              this.$router.push({ name: 'Member Token Register', params: { token: this.suscripcion.miembro.token } })
            } */
          })
          .catch(error => {
            console.log(error)
            this.text = 'El registro no puede ser modificado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .text-needs-wrapping {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
</style>
